import styles from "@styles/components/elements/quantity-input.module.scss";
import { ChangeEvent, MouseEvent } from "react";

type QuantityInputProps = {
  currentQuantity: number;
  maximumQuantity?: number;
  minimumQuantity?: number;
  handleMinusClick: () => void;
  handlePlusClick: () => void;
  customStyles?: string[];
};

export default function QuantityInput({
  currentQuantity,
  maximumQuantity,
  minimumQuantity = 1,
  handleMinusClick,
  handlePlusClick,
  customStyles = [],
}: QuantityInputProps) {
  const atMaximumQuantity = (quantity: number): boolean => {
    if (maximumQuantity) {
      return quantity >= maximumQuantity;
    }
    return false;
  };

  const atMinimumQuantity = (quantity: number): boolean => {
    return quantity === minimumQuantity;
  };

  const minOptionClick = (event: MouseEvent) => {
    event.preventDefault();
    handleMinusClick();
  };

  const plusOptionClick = (event: MouseEvent) => {
    event.preventDefault();
    if (!atMaximumQuantity(currentQuantity)) {
      handlePlusClick();
    }
  };

  const inputChange = (event: ChangeEvent) => {
    event.preventDefault();
  };

  return (
    <>
      <div
        className={`${styles.qSelector} ${
          customStyles.length > 0 ? styles[customStyles[0]] : ""
        } q-selector`}
      >
        <button
          className={`${styles.qBtn} ${
            customStyles.length > 0 ? customStyles[1] : ""
          } minus ${atMinimumQuantity(currentQuantity) ? "disabled" : ""}`}
          type="button"
          aria-label="Minus quantity"
          onClick={(e) => minOptionClick(e)}
        >
          <i className={`${styles.qIcon} fas fa-minus`}></i>
        </button>
        <input
          className={styles.qInput}
          type="text"
          readOnly={true}
          value={currentQuantity}
          onChange={(e) => inputChange(e)}
          aria-label="Quantity input"
        />
        <button
          className={`${styles.qBtn} ${
            customStyles.length > 0 ? customStyles[1] : ""
          } plus ${atMaximumQuantity(currentQuantity) ? "disabled" : ""}`}
          type="button"
          aria-label="Plus quantity"
          onClick={(e) => plusOptionClick(e)}
        >
          <i className={`${styles.qIcon} fas fa-plus`}></i>
        </button>
      </div>
    </>
  );
}
