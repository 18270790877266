import styles from "@styles/errors/not-found.module.scss";
import Link from "next/link";
import SEO from "@src/lib/seo";

export default function NotFound({
  type,
  children,
}: {
  type?: string;
  children?: any;
}) {
  const _type = type || "404";
  return (
    <>
      <SEO title={"404 Page Not Found"} />
      <div className={styles.notFoundContainer}>
        <div className={styles.imgContainer}>
          <img src="/errors/404-cyberspace.webp" />
        </div>
        <div className={styles.textContainer}>
          <h2>Error code: </h2>
          <h1>{_type}</h1>
          {_type === "404" ? (
            <>
              <p>
                This page is lost in kitty cyberspace.
                <br />
                Please check the URL and try again.
                <br />
                <br />
                Here are some helpful links:
              </p>
              <div className={styles.links}>
                <Link href="/" className={styles.link}>
                  {" "}
                  Homepage{" "}
                </Link>
                <Link
                  href="/collections/all"
                  className={`${styles.link} ${styles.alignRight}`}
                >
                  {" "}
                  Products{" "}
                </Link>
                <Link href="/account/auto-ship" className={styles.link}>
                  {" "}
                  Your Account{" "}
                </Link>
                <Link
                  href="https://help.kittypooclub.com/"
                  target="_blank"
                  className={`${styles.link} ${styles.alignRight}`}
                >
                  {" "}
                  FAQ{" "}
                </Link>
              </div>
            </>
          ) : (
            <>
              <p>
                Meow! We seem to have an error!
                <br />
                <br />
                Try refreshing the page and try again.
              </p>
              <div>{children}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
