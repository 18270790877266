import { useContext } from "react";
import { StoreContext } from "@context/store.context";
import styles from "@styles/components/cart/cart-header.module.scss";

export default function CartHeader({ showUpsell = false }) {
  const { setCartOpen } = useContext(StoreContext) || {};

  return (
    <div className={styles.cartHeader}>
      <h1 className={styles.cartHeaderH1}>Your Cart</h1>
      <button
        className={`${styles.cartCloseBtn} ${
          showUpsell ? styles.showUpsell : ""
        } btn close-icon corner`}
        onClick={() => {
          if (setCartOpen) {
            setCartOpen(false);
          }
        }}
      ></button>
    </div>
  );
}
