import { builder } from "@builder.io/react";
import { GetStaticPropsContext } from "next";
import { getRootSections } from "@services/builderIO/root.sections";
import builderConfig from "@config/builder";

import "@src/builder-registry";

builder.init(builderConfig.apiKey);

export const getAndJoinBuilderStaticProps = async (
  pageProps?: any,
  context?: GetStaticPropsContext,
  models?: string[]
) => {
  const data = pageProps ? await pageProps(context) : {};
  const _sections = await getRootSections(models || []);

  return {
    props: {
      data,
      sections: _sections,
    },
    revalidate: 20,
  };
};
