export const log = async (msg: string, ...objs: any) => {
  if (process.env.SNJS_DEBUG) {
    objs = await Promise.all(objs.map((o: any) => tryStringify(o)));

    if (typeof window === "undefined") {
      console.groupCollapsed(`[[ ${msg} ]]`, ...(objs || "trace"));
      console.trace();
      console.groupEnd();
    } else {
      console.groupCollapsed(msg, ...(objs || "trace"));
      console.trace();
      console.groupEnd();
    }
  } // TODO production log
};

export const tryParse = (payload: string) =>
  tryOrLog(() => JSON.parse(payload)) || {};

export const tryStringify = (obj: object) =>
  tryOrLog(() => JSON.stringify(obj)) || "";

export const tryOrLog = (func: () => string, msg = "") => {
  try {
    return func();
  } catch (error: Error | any) {
    log(`${msg} ${error.message}`, error);
    return null;
  }
};

export const tryOrLogAsync = async (promise: any, msg = "") => {
  try {
    return await promise;
  } catch (error: Error | any) {
    log(`${msg} ${error.message}`, error);
    return null;
  }
};
