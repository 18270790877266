import beaconOpen from "@lib/beacon.ts";
import styles from "@styles/components/elements/beacon.module.scss";

import { usePathname } from "next/navigation";

export default function BeaconHelp({ isTop = false }) {
  const pathname = usePathname();
  return (
    <>
      <div
        className={`${styles.help_me} ${
          isTop ? styles.helpTop : styles.helpBottom
        }`}
      >
        <button
          onClick={() => beaconOpen(pathname)}
          className={styles.btnBeacon}
          aria-label="Need help? Chat with our team"
        >
          <i className="fas fa-comment-dots"></i>
        </button>
      </div>
    </>
  );
}
