import { useCartLine, useCart } from "@shopify/hydrogen-react";
import CartQuantitySelector from "@components/cart/cart-quantity-selector.component";
import styles from "@styles/components/cart/cart-item.module.scss";
import { formatPrice } from "@lib/product-utilities";

import useMediaQuery from "@lib/hooks/mediaMatch.hook";
import {
  CartLine,
  Product,
} from "@shopify/hydrogen-react/storefront-api-types";

export default function CartItem() {
  const cartLine = useCartLine() as CartLine;
  const { linesRemove } = useCart();

  const productDetails: Product = cartLine.merchandise.product;
  const isMobile: boolean | undefined = useMediaQuery(`(max-width: 550px)`);
  return (
    <div className={styles.cartItem}>
      {!isMobile ? (
        <div className={styles.cartGrid}>
          <div className={styles.cartItemImage}>
            <img
              src={cartLine.merchandise.image?.url}
              className={styles.image}
            />
          </div>
          <div className={styles.cartItemDescription}>
            <div>
              <h4 className={styles.cartItemH4}>{productDetails.title}</h4>
              <p>
                {cartLine.sellingPlanAllocation &&
                cartLine.sellingPlanAllocation.sellingPlan &&
                cartLine.sellingPlanAllocation.sellingPlan.name
                  ? cartLine.sellingPlanAllocation.sellingPlan.name
                  : "Once"}
              </p>
            </div>
            <div className={styles.cartItemQuantityAutoship}>
              <CartQuantitySelector product={cartLine} />
            </div>
          </div>

          <div className={styles.cartItemPriceRemove}>
            <span className={styles.cartItemPrice}>
              ${formatPrice(cartLine.cost.totalAmount.amount)}
            </span>
            <a
              className={styles.cartItemRemove}
              title={`remove ${productDetails.title} from cart`}
              aria-label={`remove ${productDetails.title} from cart`}
              onClick={() => {
                linesRemove([cartLine.id]);
              }}
            >
              <span className={styles.textRemove}>Remove</span>
              <span className={`${styles.iconRemove} icon-remove icon`}></span>
            </a>
          </div>
        </div>
      ) : (
        <div className={styles.cartGrid}>
          <div className={styles.cartItemImage}>
            <img
              src={cartLine.merchandise.image?.url}
              className={styles.image}
            />
          </div>
          <div className={styles.cartItemDescription}>
            <div>
              <h4 className={styles.cartItemH4}>{productDetails.title}</h4>
              <p>
                {cartLine.sellingPlanAllocation &&
                cartLine.sellingPlanAllocation.sellingPlan &&
                cartLine.sellingPlanAllocation.sellingPlan.name
                  ? cartLine.sellingPlanAllocation.sellingPlan.name
                  : "Once"}
              </p>
              <span className={styles.cartItemPrice}>
                ${formatPrice(cartLine.cost.totalAmount.amount)}
              </span>
            </div>
            <div className={styles.cartItemQuantityAutoship}>
              <CartQuantitySelector product={cartLine} />
            </div>
          </div>
          <a
            className={styles.cartItemRemove}
            title={`remove ${productDetails.title} from cart`}
            aria-label={`remove ${productDetails.title} from cart`}
            onClick={() => linesRemove([cartLine.id])}
          >
            <span className={`${styles.iconRemove} icon-remove icon`}></span>
          </a>
        </div>
      )}
    </div>
  );
}
