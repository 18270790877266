import TagManager from "react-gtm-module";
import { useEffect } from "react";
import { ErrorBoundary } from "@sentry/nextjs";
import { HydrationOverlay } from "@builder.io/react-hydration-overlay";
import {
  ShopifyProvider,
  sendShopifyAnalytics,
  getClientBrowserParameters,
  AnalyticsEventName,
  useShopifyCookies,
  CartProvider,
} from "@shopify/hydrogen-react";
import { useRouter } from "next/router";
import { cartDrawerFragment } from "@services/shopify/queries";

import "@styles/global.scss";
import AuthProvider from "@context/auth.context";
import StoreProvider from "@context/store.context";
import CmsBridgeProvider from "@context/cms.bridge.context";
import NotFound from "@components/errors/not-found.component.tsx";
import type { AppProps } from "next/app";
import shopifyConfig from "@config/shopify";
import type { ShopifyAnalyticsProduct } from "@src/types/product.type";

import "../builder-registry";

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const myFallback = <NotFound type={"500"} />;
const analyticsShopData = shopifyConfig.analyticsShopData;

interface ShopifyPageViewPayload {
  hasUserConsent: boolean;
  canonicalUrl?: string;
  pageType?: string;
  resourceId?: string;
  collectionHandle?: string;
  customerId?: string;
  searchString?: string;
  products?: ShopifyAnalyticsProduct[];
}

function sendPageView(analyticsPageData: any) {
  const payload = {
    ...getClientBrowserParameters(),
    ...analyticsPageData,
  };
  sendShopifyAnalytics(
    {
      eventName: AnalyticsEventName.PAGE_VIEW,
      payload,
    },
    shopifyConfig.storeDomain
  );
}

export default function KPCapp({ Component, pageProps }: AppProps) {
  const hasUserConsent = true; // yourFunctionToDetermineIfUserHasConsent();
  const dynamicRoute = useRouter().asPath;
  const clientBrowserParameters = getClientBrowserParameters();
  const analytics: ShopifyPageViewPayload = {
    hasUserConsent,
    ...analyticsShopData,
    canonicalUrl: clientBrowserParameters.url,
    pageType: pageProps.type || "article",
    ...(pageProps.productAnalytics
      ? { products: [pageProps.productAnalytics] }
      : {}),
    ...(pageProps.collectionHandle
      ? { products: pageProps.collectionHandle }
      : {}),
  };
  const pagePropsWithAppAnalytics = {
    ...pageProps,
    analytics,
  };
  useEffect(() => {
    sendPageView(analytics);
  }, [analytics, dynamicRoute]);

  useShopifyCookies();

  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID! });
  }, []);

  return (
    <HydrationOverlay>
      <ErrorBoundary fallback={myFallback}>
        <ShopifyProvider
          storeDomain={shopifyConfig.storeDomain!}
          storefrontToken={shopifyConfig.storefrontToken!}
          storefrontApiVersion={shopifyConfig.storefrontApiVersion!}
          countryIsoCode="US"
          languageIsoCode="EN"
        >
          <AuthProvider>
            <CmsBridgeProvider>
              <CartProvider cartFragment={cartDrawerFragment}>
                <StoreProvider props={pageProps}>
                  <Component {...pageProps} />
                </StoreProvider>
              </CartProvider>
            </CmsBridgeProvider>
          </AuthProvider>
        </ShopifyProvider>
      </ErrorBoundary>
    </HydrationOverlay>
  );
}
