import { useRouter } from "next/router";
import { NextSeo } from "next-seo";

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
}

export default function SEO({ title, description, image }: SEOProps) {
  const router = useRouter();
  let cleanedRoute = router.asPath.replace(/(\?|\#).*/g, "");

  if (title && description) {
    return (
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          siteName: "Kitty Poo Club",
          type: "website",
          title: title,
          description: description,
          locale: "en-US",
          url: `https://kittypooclub.com${cleanedRoute}`,
          images: [
            {
              url: "/KPC-meta-image-2023-newbox.webp",
              width: 800,
              height: 600,
              alt: title,
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
          site: "@kittypooclub",
          handle: "@kittypooclub",
        }}
      />
    );
  } else if (title) {
    return (
      <NextSeo
        title={title}
        description="Finally, a monthly litter box you will LOVE! Stop scrubbing nasty plastic. Unmatched odor control! Regain your home and feel good doing it."
        openGraph={{
          siteName: "Kitty Poo Club",
          type: "website",
          title: title,
          description:
            "Finally, a monthly litter box you will LOVE! Stop scrubbing nasty plastic. Unmatched odor control! Regain your home and feel good doing it.",
          locale: "en-US",
          url: `https://kittypooclub.com${cleanedRoute}`,
          images: [
            {
              url: "/KPC-meta-image-2023-newbox.webp",
              width: 800,
              height: 600,
              alt: title,
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
          site: "@kittypooclub",
          handle: "@kittypooclub",
        }}
      />
    );
  } else {
    return (
      <NextSeo
        title={`Kitty Poo Club Reinvented the Litter Box | Free Shipping`}
        description={
          "Finally, a monthly litter box you will LOVE! Stop scrubbing nasty plastic. Unmatched odor control! Regain your home and feel good doing it."
        }
        openGraph={{
          siteName: "Kitty Poo Club",
          type: "website",
          title: "Kitty Poo Club Reinvented the Litter Box | Free Shipping",
          description:
            "Finally, a monthly litter box you will LOVE! Stop scrubbing nasty plastic. Unmatched odor control! Regain your home and feel good doing it.",
          locale: "en-US",
          url: `https://kittypooclub.com${cleanedRoute}`,
          images: [
            {
              url: "/KPC-meta-image-2023-newbox.webp",
              width: 800,
              height: 600,
              alt: "Kitty Poo Club | Reinvented the Litter Box | Free Shipping",
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
          site: "@kittypooclub",
          handle: "@kittypooclub",
        }}
      />
    );
  }
}
