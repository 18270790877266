import * as GraphQL from "./queries";
import { setDetails, isPrimary } from "./product.data";
import shopifyConnect from "./shopify";
import builderConfig from "@config/builder";

interface CollectionPrams {
  handle: string;
  sort?: string;
  reverse?: boolean;
  to?: number;
  cursor?: string;
  vendors?: string[];
}

interface CollectionFilters {
  filters: any;
  handle: string;
  vendor?: string;
  pullFilters?: boolean;
}

const getAvailableProducts = (products: any) => {
  let _prods = products.edges.filter((p: any) =>
    p.node.variants.edges.find((v: any) => v.node.availableForSale)
  );

  _prods = _prods.filter(
    (prod: any) =>
      ![
        "4543792480352",
        "6621721526368",
        "758684844121",
        "4883643203631",
        "4883641991215",
        "7132583723055",
      ].includes(prod.node.id.split("/").pop())
  );

  return _prods.map((lineitem: any) => {
    const _d = setDetails(lineitem.node);
    return {
      ..._d,
      cursor: lineitem.cursor,
    };
  });
};

const getFilters = async ({
  filters,
  handle,
  vendor,
  pullFilters = false,
}: CollectionFilters) => {
  let count = 0;
  let _values: Array<any> = [];
  let _filterGrp = filters;
  const _filters: Array<any> = [];
  const _allowed = [{ pattern: /^filter.p.vend/i, label: "Brand" }];

  if (handle === "all") {
    _allowed.push({ pattern: /^filter.p.tag.type/i, label: "Type" });
  }

  if (pullFilters) {
    await shopifyConnect(GraphQL.collectionFilters(handle, vendor)).then(
      (resp) => {
        if (
          resp.data &&
          resp.data.collection &&
          resp.data.collection.products.filters
        ) {
          _filterGrp = resp.data.collection.products.filters;
        }
      }
    );
  }

  if (filters || handle) {
    _values = _filterGrp[0].values.concat(_filterGrp[1].values);
  }
  if (vendor) {
    const _brands = _filterGrp[0].values.filter((b: any) => b.label === vendor);
    _values = _brands.concat(_filterGrp[1].values);
  }

  const isAllowed = (id: string) => {
    const _item = _allowed.filter((a) => a.pattern.test(id));
    return _item.length === 1 ? _item[0].label : false;
  };

  const makeLabel = (label: string[]) => {
    label.shift();
    return label
      .map((s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase())
      .join(" ");
  };

  if (_values.length >= 1) {
    _values.forEach((filter: any) => {
      const type = isAllowed(filter.id);
      if (type && filter.count >= 1) {
        const _label = makeLabel(filter.label.split("-"));
        let _value = { label: _label, input: JSON.parse(filter.input) };
        if (type === "Brand") {
          _value = { ..._value, label: filter.label };
        }

        const _grp = _filters.filter((f) => f.label === type);

        if (_grp.length >= 1) {
          _grp[0].items.push(_value);
        } else {
          _filters.push({ label: type, items: [_value] });
        }
      }
      if (filter.id.includes("recurly")) {
        count = filter.count;
      }
    });
  }

  return { filters: _filters, totalCount: count };
};

// Format collection data
export const collectionData = (collection: any) => {
  // Product data
  const collectionProducts: any = [];
  let endCursor = null;
  collection.products.edges.map((prod: any) => {
    if (!prod.hidden) {
      const _image = prod.featuredImage.url
        ? prod.featuredImage.url
        : prod.featuredImage;
      collectionProducts.push({
        handle: prod.handle,
        link: `${builderConfig.product_links}${prod.handle}`,
        title: prod.title,
        tiers: prod.tiers,
        image: _image,
        limit: +prod.totalInventory >= 1 ? prod.totalInventory : 1000,
        featuredImage: _image,
        price: prod.priceRange.maxVariantPrice
          ? prod.priceRange.maxVariantPrice.amount
          : prod.priceRange.max,
        compareAtPrice:
          (prod.variants && prod.variants[0].compareAtPrice) || null,
        pid: prod.pid,
        rid: `${prod.pid}_${prod.variants[0].vid}`,
        vid: prod.variants[0].vid,
        primary: isPrimary(prod),
        productType: prod.productType,
        variants: prod.variants,
        sellingPlan: prod.defaultSellingPlan
          ? prod.defaultSellingPlan.value
          : false,
        tags: prod.tags,
      });
    }
    endCursor = prod.cursor;
  });

  // Collection data
  const _collection: any = {
    handle: collection.handle,
    title: collection.title,
    description: collection.description,
    image: collection.image ? collection.image.url : null,
    showingCount: collectionProducts.length,
    totalCount: collection.totalCount,
    cursor: endCursor,
  };
  return { collection: _collection, productList: collectionProducts };
};

export async function getCollectionsByHandles({ handles, limit = 12 }: any) {
  const _collections: Array<any> = [];
  await Promise.all(
    handles.map(async (_handle: string, idx: number) => {
      const resp = await getCollectionProductsByHandle({
        handle: _handle,
        to: limit,
      });
      if (resp) {
        _collections[idx] = resp;
      }
    })
  );
  return _collections;
}

export async function getCollectionProductsByHandle({
  handle,
  sort,
  reverse,
  to,
  cursor,
  vendors,
}: CollectionPrams) {
  const data = GraphQL.collectionByHandle(
    handle,
    sort,
    reverse,
    to,
    cursor,
    vendors
  );

  return await shopifyConnect(data).then(async (res) => {
    if (
      res.data.collection &&
      res.data.collection.products &&
      res.data.collection.products.edges.length >= 1
    ) {
      const { products, ...collection } = res.data.collection;
      const _products = getAvailableProducts(products);

      if (products.filters && products.filters.length >= 1) {
        const _filters = await getFilters({
          filters: products.filters,
          handle,
        });

        // Adjust Total Count
        if (products.edges.length !== _products.length) {
          _filters.totalCount -= products.edges.length - _products.length;
        }

        return collectionData({
          ...collection,
          ..._filters,
          products: { edges: _products, pageInfo: products.pageInfo },
        });
      } else {
        return collectionData({
          ...collection,
          products: { edges: _products, pageInfo: products.pageInfo },
        });
      }
    } else {
      return {};
    }
  });
}

export async function getAllCollections() {
  const resp = await shopifyConnect(GraphQL.collections());
  if (resp && resp.data.collections) {
    const _filtered = resp.data.collections.edges
      .filter(
        (collection: any) =>
          collection.node.hidden === null &&
          collection.node.menuItem !== null &&
          collection.node.products.pageInfo.endCursor
      )
      .map((c: any) => {
        const _image = c.node.image
          ? c.node.image.src
          : c.node.products.edges[0].node.featuredImage.url;
        return {
          handle: c.node.handle,
          title: c.node.title,
          image: _image,
          featuredImage: _image,
          link: `/collections/${c.node.handle}`,
        };
      });

    return _filtered;
  }
  return false;
}
