export const ProductType = (type) => {
  const [container, litter, boxsize] = type.split("|");
  if (container === "box-only") {
    return { container, litter: undefined, boxsize: litter };
  }
  return { container, litter, boxsize };
};

export const LitterType = (type) => {
  if (type === "corn") {
    return "Corn & Wheat";
  }
  if (type === "organic") {
    return "Soy";
  }
  if (type === "health") {
    return "Health Monitoring";
  }
  if (type === "fine-grain") {
    return "Fine-Grain Silica";
  }
  if (type === undefined) {
    return "No Litter - Box Only";
  }
  return type[0].toUpperCase() + type.slice(1);
};

export function findVariant(q, variants, option) {
  let _variant = variants[0];
  if (option) {
    const _reduction = variants.filter((v) => v[option] <= q);

    if (_reduction.length > 1) {
      _variant = _reduction.reduce((prev, curr) =>
        Math.abs(curr[option] - q) < Math.abs(prev[option] - q) ? curr : prev
      );
    }
  }
  return _variant;
}

export function tierPricing(product, quantity, current_variant) {
  const { pid, tiers, id, primary } = product;
  const variants = product.variants ? product.variants : product.data.variants;
  let _variants = variants;
  if (variants.edges) {
    _variants = variants.edges.map((n) => {
      return {
        ...n.node,
        vid: n.node.vid || n.node.id.split("/").pop(),
      };
    });
  }
  let variant = current_variant || _variants[0];
  let unit_price = variant.price;
  let _pid = pid || id.split("/").pop();
  let _vid = variant.vid || variant.id.split("/").pop();
  let rid = `${_pid}_${_vid}`;

  if (tiers && quantity !== 0 && primary) {
    let _tier = tiers[0];
    if (tiers.length > 1) {
      const _reduction = tiers.filter((t) => +t.tier <= quantity);
      if (_reduction.length > 1) {
        _tier = _reduction.reduce((prev, curr) =>
          Math.abs(curr.tier - quantity) < Math.abs(prev.tier - quantity)
            ? curr
            : prev
        );
      } else {
        _tier = _reduction[0];
      }

      if (_tier) {
        variant = _variants.find((v) => v.vid === _tier.vid);
        rid = `${pid}_${variant.vid}`;
        unit_price =
          _tier.price && _tier.price.amount ? _tier.price.amount : _tier.price;
      }
    }
  }
  return {
    unit_price: unit_price,
    variant: variant,
    rid: rid,
    vid: variant.vid || _vid,
  };
}

export function switchProduct(collection, type, quantity) {
  let _type = { ...type };
  if (!_type.boxsize) {
    delete _type.boxsize;
  }

  if (_type.container === "box-only") {
    delete _type.litter;
  }
  _type = Object.values(_type).join("|");
  const _product = collection.find((prod) => prod.productType === _type);
  if (_product) {
    const _pid = _product.id.split("/").pop();
    const _variant = findVariant(quantity, _product.variants, "tier");

    return {
      pid: _pid,
      rid: `${_pid}_${_variant.vid}`,
      variant: _variant,
      variants: _product.variants,
      img: _product.featuredImage.url,
      form_options: productCollectionOptions(_type, collection),
    };
  }
  return false;
}

const productCollectionOptions = (productType, collection) => {
  const currentType = ProductType(productType);
  let litterOptions = [];
  if (currentType.container === "box") {
    litterOptions = collection
      .filter((item) => {
        if (item.productType.includes(`${currentType.container}|`)) {
          if (currentType.boxsize) {
            return item.productType.includes("|xl");
          }
          return !item.productType.includes("|xl");
        } else if (item.productType.includes("box-only")) {
          if (currentType.boxsize) {
            return item.productType.includes("|xl");
          }
          return !item.productType.includes("|xl");
        }
      })
      .map((type) => {
        const _type = ProductType(type.productType);
        return {
          value: _type.litter !== undefined ? _type.litter : "box-only",
          name: LitterType(_type.litter),
        };
      });
  } else {
    litterOptions = collection
      .filter((item) => {
        return !item.productType.includes("|xl");
      })
      .map((item) => {
        const _type = ProductType(item.productType);
        return {
          value: _type.litter !== undefined ? _type.litter : "box-only",
          name: LitterType(_type.litter),
        };
      });
    litterOptions = litterOptions.filter(
      (item, index, self) =>
        index === self.findIndex((option) => option.value === item.value)
    );
    litterOptions.sort((typeA, typeB) => typeA.name.localeCompare(typeB.name));
  }

  const boxSizeOptions = [
    {
      value: "",
      name: "Standard Litter Box",
    },
    {
      value: "bag",
      name: "No Box - Bag Only",
    },
  ];

  const hasXL = collection.find((item) => {
    return item.productType.includes(`${currentType.litter}|xl`);
  });
  if (hasXL || currentType.container === "box-only") {
    boxSizeOptions.splice(1, 0, {
      value: "xl",
      name: "XL Litter Box",
    });
  }

  let currentBoxType = "";
  if (currentType.container === "bag") {
    currentBoxType = "bag";
    litterOptions = litterOptions.filter((item) => item.value !== "box-only");
  } else if (currentType.container === "box" && currentType.boxsize === "xl") {
    currentBoxType = "xl";
  } else if (currentType.container === "box-only") {
    currentBoxType =
      productType.split("|")[1] !== undefined ? productType.split("|")[1] : "";
    boxSizeOptions.splice(2, 1);
  }

  const _formOptions = {
    name: "primary_options",
    formGroup: [
      {
        elements: [
          {
            style: "form-grid-cols gap-half full-col",
            id: "litter_type",
            type: "select",
            label: "Litter Type:",
            labelCtaClass: "label-cta",
            value: currentType.litter ? currentType.litter : "box-only",
            options: litterOptions,
          },
        ],
      },
    ],
  };

  _formOptions.formGroup.push({
    elements: [
      {
        style: "form-grid-cols gap-half full-col",
        id: "box_size",
        type: "select",
        label: "Litter Box Options:",
        labelCtaClass: "label-cta",
        value: currentBoxType,
        options: boxSizeOptions,
      },
    ],
  });
  return _formOptions;
};

export function productFormOptions(product, collection, variants) {
  if (collection) {
    return productCollectionOptions(product.data.productType, collection);
  }

  if (variants.length === 1) {
    return false;
  }

  const _options = variants.map((v) => {
    return {
      value: v.vid,
      name: v.title,
    };
  });
  if (product.data.options) {
    return {
      name: "product_variant",
      formGroup: [
        {
          elements: [
            {
              style: "form-flex",
              id: "variant",
              type: "select",
              label: `${product.data.options[0].name}:`,
              value: product.vid,
              options: _options,
            },
          ],
        },
      ],
    };
  }
}

export function pdpDropdownOptions(product) {
  if (product.variants.length === 1) {
    return false;
  }

  const _options = product.variants.map((v) => {
    return {
      value: v.vid,
      name: v.title,
    };
  });

  if (product.options) {
    return {
      name: "product_variant",
      formGroup: [
        {
          elements: [
            {
              style: "form-flex",
              id: "variant",
              type: "select",
              label: `${product.options[0]}:`,
              value: product.vid,
              options: _options,
            },
          ],
        },
      ],
    };
  }
}

export function formatPrice(number) {
  return Number(number).toFixed(2);
}

export const getItemTotal = (item) => {
  const _vid = item.vid;
  const _variants = item.data ? item.data.variants : item.variants;
  const _variant = _vid ? _variants.find((v) => v.vid === _vid) : _variants[0];
  const _productTier = tierPricing(item.data || item, item.quantity, _variant);
  return _productTier.unit_price * item.quantity;
};
