import { setCookie, getCookie, deleteCookie } from "cookies-next";

function hasSessionStorage(): boolean {
  try {
    const mod: string = "_storage_test_";
    sessionStorage.setItem(mod, mod);
    sessionStorage.removeItem(mod);
    return true;
  } catch (e: Error | any) {
    return false;
  }
}

export function getState(id: string): any {
  let _state: any = sessionStorage.getItem(id);
  if (!hasSessionStorage() || !_state) {
    _state = getCookie(id);
  }
  if (_state) {
    try {
      return JSON.parse(_state) || false;
    } catch (err) {
      return _state;
    }
  }
  return false;
}

export function saveState(id: string, data: any): void {
  if (hasSessionStorage()) {
    sessionStorage.setItem(id, JSON.stringify(data));
  } else {
    setCookie(id, JSON.stringify(data), {
      path: "/",
    });
  }
}

export function removeState(id: string): void {
  if (hasSessionStorage()) {
    sessionStorage.removeItem(id);
  } else {
    deleteCookie(id);
  }
}
