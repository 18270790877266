import { getState, saveState } from "@lib/state.management.ts";

const helpscout_beacon_id = process.env.NEXT_PUBLIC_BEACON_ID;

export default function beaconOpen(
  currentPath: string,
  type: string = "answer"
) {
  const _saved_customer = getState("kpc_customer");
  const beaconExists = getState("kpc_help_beacon");
  const _text = "";
  const _subject = "";
  try {
    const Beacon = (window as any).Beacon;

    if (currentPath.includes("join-the-club")) {
      currentPath = "join-the-club";
    } else if (currentPath.includes("account")) {
      currentPath = "account";
    } else if (currentPath.includes("about")) {
      currentPath = "about";
    } else if (currentPath.includes("collections")) {
      currentPath = "collections";
    }

    if (!Object.keys(beaconExists).includes(currentPath)) {
      Beacon("init", helpscout_beacon_id);
      saveState("kpc_help_beacon", { ...beaconExists, [currentPath]: true });
    }
    Beacon("toggle");
    if (type === "ask") {
      if (_text) {
        Beacon("navigate", "/ask/message/");
      } else {
        Beacon("navigate", "/ask/chat/");
      }
      if (_saved_customer) {
        Beacon("prefill", {
          name: `${_saved_customer.shopify.firstName} ${_saved_customer.shopify.lastName}`,
          email: _saved_customer.shopify.email,
          subject: _subject,
          text: _text,
          fields: [],
        });
      }
    }
  } catch (err) {
    console.warn(err);
  }
}
