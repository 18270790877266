import { useContext, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { StoreContext } from "@context/store.context";

import CartHeader from "./cart-header.component";
import CartBody from "./cart-body.component";
import CartFooter from "./cart-footer.component";
import CartUpsell from "./cart-upsell.component";

import styles from "@styles/components/cart/cart.module.scss";

export default function Cart() {
  const { cartOpen, setCartOpen, emptyCart } = useContext(StoreContext) || {};
  const [showUpsell, setShowUpsell] = useState<boolean>(false);

  /*
  const shouldShowUpsell = () => {
    if (!cart || cart.lines.edges.length === 0) {
      setShowUpsell(false);
      return;
    }
    for (const item of cart.lines.edges) {
      if (item.node && (new RegExp(/box|bag/g).test(item.node.merchandise.product.productType))) {
        setShowUpsell(false);
        return;
      }
    }
    setShowUpsell(true);
  };
  */

  const handleEmptyCartClick = () => {
    if (emptyCart) {
      emptyCart();
    }
  };

  return (
    <>
      <Transition appear show={cartOpen} as={Fragment}>
        <Dialog
          onClose={() => {
            if (setCartOpen) {
              setCartOpen(false);
            }
          }}
          className="cover"
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-linear duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fuzzy" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-linear duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Panel
              className={`${styles.cartModal} ${
                showUpsell ? styles.showUpsell : ""
              }`}
            >
              {showUpsell && <CartUpsell />}
              <div className={styles.cartGrid}>
                <CartHeader showUpsell={showUpsell} />
                <CartBody handleEmptyCartClick={handleEmptyCartClick} />
                <CartFooter showUpsell={showUpsell} />
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
}
