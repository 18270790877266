import { createContext, useState, useEffect } from "react";
import { tierPricing } from "@lib/product.utilities";
import { Product } from "@src/types/product.type";

export const ProductType = (type: string) => {
  const [container, litter, boxsize] = type.split("|");
  if (container === "box-only") {
    return { container, litter: undefined, boxsize: litter };
  }
  return { container, litter, boxsize };
};

export interface ProductContextProps {
  autoShip: boolean;
  hasChanged?: boolean;
  productData: Product;
  total: number;
  quantity: number;
  variant: any;
  handleFormChange: (formState: any) => void;
  setVariant: (variant: any) => void;
  setQuantity: (quantity: number) => void;
  setAutoShip: (autoShip: boolean) => void;
}

export interface ShopifyVariant {
  edges: {
    node: {
      id: string;
      title: string;
      price: string;
      compareAtPrice: string;
      availableForSale: boolean;
      selectedOptions: {
        name: string;
        value: string;
      }[];
    };
  }[];
}

interface ProductProviderV2Props {
  product: Product;
  allowZeroQty?: boolean;
  handleChange?: (e: any) => void;
  children?: React.ReactElement;
}

const ProductContextV2 = createContext<ProductContextProps>({
  autoShip: false,
  productData: {
    autoShip: false,
    collection: { title: "", handle: "", menuItem: false },
    compareAtPrice: false,
    description: "",
    featuredImage: "",
    handle: "",
    id: "",
    isBox: false,
    mediaList: [],
    options: [],
    pid: "",
    price: 0,
    priceRange: { max: 0, min: 0 },
    primary: false,
    productType: "",
    tags: [],
    title: "",
    tiers: [],
    variants: [],
    vendor: "",
    sellingPlanGroups: [],
  },
  quantity: 1,
  total: 0,
  variant: {},
  setVariant: () => {},
  setQuantity: () => {},
  setAutoShip: () => {},
  handleFormChange: () => {},
});

export default function ProductProviderV2({
  product,
  allowZeroQty,
  handleChange,
  children,
}: ProductProviderV2Props) {
  const _defaultQuantity = allowZeroQty ? 0 : 1;
  const [productData, setProductData] = useState(product);
  const [quantity, setQuantity] = useState(
    product.quantity || _defaultQuantity
  );
  const [variant, setVariant] = useState(
    product.variant || product.variants[0]
  );
  const [autoShip, setAutoShip] = useState(product.autoShip || false);
  const [total, setTotal] = useState(0);
  const [hasChanged, setHasChanged] = useState(false);

  const handleFormChange = (formState: any) => {
    if (formState.changedKey === "variant") {
      let variant = productData.variants.find((v) => v.vid === formState.value);
      if (variant) {
        setProductData({
          ...productData,
          price: +variant?.price ?? 0,
          variant: variant,
          vid: formState.value,
        });
      }
    }
  };

  const updateValues = (productObj: Product, changed: boolean) => {
    const _productTier = tierPricing(productObj, quantity, variant);
    const _total = Number((+_productTier.unit_price * quantity).toFixed(2));
    setTotal(_total);
    setVariant(_productTier.variant);
    setProductData({
      ...productObj,
      autoShip: autoShip,
      price: +_productTier.unit_price,
      quantity: quantity,
      rid: _productTier.rid,
      vid: _productTier.vid,
      variant: _productTier.variant,
    });
    setHasChanged(changed);
  };

  useEffect(() => {
    updateValues(product, false);
  }, [product]);

  useEffect(() => {
    if (total !== 0) {
      updateValues(productData, true);
    }
  }, [quantity, autoShip]);

  return (
    <ProductContextV2.Provider
      value={{
        autoShip,
        hasChanged,
        productData,
        quantity,
        total,
        variant,
        setVariant,
        setQuantity,
        setAutoShip,
        handleFormChange,
      }}
    >
      {children}
    </ProductContextV2.Provider>
  );
}

const ProductConsumerV2 = ProductContextV2.Consumer;

export { ProductConsumerV2, ProductContextV2 };
