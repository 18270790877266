import type { Product, Variants } from "@src/types/product.type";

export function tierPricing(
  product: Product,
  quantity: number,
  current_variant?: Variants
) {
  const { pid, tiers, primary } = product;

  let variants = product.variants;
  let variant = current_variant || variants[0];
  let unit_price = variant.price;
  let vid = variant.vid;
  let rid = `${pid}_${vid}`;

  if (tiers && quantity !== 0 && primary) {
    let _tier = tiers[0];
    if (tiers.length > 1) {
      const _reduction = tiers.filter((t: any) => +t.tier <= quantity);
      if (_reduction.length > 1) {
        _tier = _reduction.reduce((prev: any, curr: any) =>
          Math.abs(curr.tier - quantity) < Math.abs(prev.tier - quantity)
            ? curr
            : prev
        );
      } else {
        _tier = _reduction[0];
      }

      if (_tier) {
        variant = variants.find((v) => v.vid === _tier.vid) || variant;
        rid = `${pid}_${variant.vid}`;
        unit_price = _tier.price;
      }
    }
  }
  return {
    unit_price: unit_price,
    variant: variant,
    rid: rid,
    vid: variant.vid || vid,
  };
}
