import styles from "@styles/components/sections/guarantee-badges.module.scss";

export default function GuaranteeBadges() {
  return (
    <div className={styles.badges}>
      <img src="/svgs/NoRisk-Badge.svg" className={styles.badge} />
      <img src="/svgs/SSL-Badge.svg" className={styles.badge} />
    </div>
  );
}
