import styles from "@styles/components/elements/button.module.scss";
import { CSSProperties, MouseEventHandler } from "react";

export interface ButtonProps {
  children?: React.ReactElement;
  text?: string;
  title?: string;
  ariaLabel?: string;
  style?: CSSProperties;
  className?: string;
  id?: string;
  isActive?: boolean;
  isIconLeft?: boolean;
  isEdit?: boolean;
  isCancel?: boolean;
  handleClick: MouseEventHandler<HTMLButtonElement>;
  isDelete?: boolean;
  isWarn?: boolean;
  hasIcon?: boolean;
  isLoginSignup?: boolean;
  isCheckoutEdit?: boolean;
  isInverse?: boolean;
}

export default function Button({
  children,
  handleClick = () => {},
  text,
  title,
  ariaLabel,
  style,
  id,
  className,
  isActive = true,
  isIconLeft = false,
  isEdit = false,
  isCancel = false,
  isDelete = false,
  isWarn = false,
  hasIcon = false,
  isLoginSignup = false,
  isCheckoutEdit = false,
  isInverse = false,
}: ButtonProps) {
  const populateClassNames = () => {
    let _classnames = [styles.btn];
    if (className) _classnames.push(className);
    if (!isActive) _classnames.push(styles.disabled);
    if (isEdit) _classnames.push(styles.edit);
    if (isCancel) _classnames.push(styles.cancel);
    if (isDelete) _classnames.push(styles.delete);
    if (isWarn) _classnames.push(styles.warn);
    if (hasIcon) _classnames.push(styles.icon);
    if (isIconLeft) _classnames.push(styles.iconLeft);
    if (isLoginSignup) _classnames.push(styles.login);
    if (isCheckoutEdit) _classnames.push(styles.checkoutEdit);
    if (isInverse) _classnames.push(styles.inverse);

    return _classnames.join(" ");
  };

  return (
    <button
      id={id}
      className={populateClassNames()}
      title={title}
      aria-label={ariaLabel}
      onClick={handleClick}
      style={style}
      disabled={!isActive}
    >
      {isIconLeft ? (
        <span>
          {" "}
          {children} {text}{" "}
        </span>
      ) : (
        <span>
          {" "}
          {text} {children}{" "}
        </span>
      )}
    </button>
  );
}
