import styles from "@styles/components/sections/cart-is-empty.module.scss";

const base_path: string = process.env.NEXT_PUBLIC_BASE_PATH!;

export default function CartIsEmpty() {
  return (
    <div className={styles.cartIsEmptyContainer}>
      <h2>Your Cart is Currently Empty</h2>
      <p>
        Ready to change your litter routine? Browse{" "}
        <a href={`${base_path}/collections`}>Kitty Poo Club</a>
      </p>
    </div>
  );
}
