import { Fragment } from "react";
import { useCart, CartLineProvider } from "@shopify/hydrogen-react";
import { CartLine } from "@shopify/hydrogen-react/storefront-api-types";

import CartItem from "./cart-item.component";
import GuaranteeBadges from "@components/cart/guarantee-badges.component";
import CartIsEmpty from "@components/cart/cart-is-empty.component";

import styles from "@styles/components/cart/cart-body.module.scss";

export default function CartBody({
  handleEmptyCartClick,
}: {
  handleEmptyCartClick: () => void;
}) {
  const cart: any = useCart();
  return (
    <div className={styles.cartBody}>
      <div className={styles.cartItemsWrapper}>
        {cart.lines && cart.lines.length > 0 ? (
          cart.lines.map((item: CartLine, idx: number) => {
            return (
              <Fragment key={idx}>
                <CartLineProvider line={item}>
                  <CartItem />
                </CartLineProvider>
              </Fragment>
            );
          })
        ) : (
          <CartIsEmpty />
        )}
      </div>
      <div className={styles.emptyCart}>
        {cart.lines && cart.lines.length > 0 && (
          <button
            onClick={(e) => {
              e.preventDefault();
              handleEmptyCartClick();
            }}
            className={styles.emptyCartBtn}
          >
            Empty Cart
          </button>
        )}
      </div>
      <GuaranteeBadges />
    </div>
  );
}
