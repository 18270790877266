import { useContext, useEffect, useState } from "react";
import { useCart } from "@shopify/hydrogen-react";
import { builder } from "@builder.io/sdk";
// Remove the import statement for CartType
import { formatPrice } from "@lib/product-utilities";
import { StoreContext } from "@context/store.context";
import Button from "@components/elements/button.component";
import styles from "@styles/components/cart/cart-footer.module.scss";
import { CartLine } from "@shopify/hydrogen-react/storefront-api-types";

export default function CartFooter({ showUpsell = false }) {
  const { couponCodes } = useContext(StoreContext) || {};
  const cart: any = useCart();

  const [oneTimeTotal, setOneTimeTotal] = useState<number>(0);
  const [autoShipTotal, setAutoShipTotal] = useState<number>(0);
  const [cartSubtotal, setCartSubtotal] = useState<number>(0);
  const [discountTotal, setDiscountTotal] = useState<number>(0);
  const [checkoutLink, setCheckoutLink] = useState<string | null>(null);

  const computeOneTimeAndAutoShipTotals = (lines: any) => {
    let oneTimeTotal = 0;
    let autoShipTotal = 0;
    let discountTotal = 0;
    if (lines.length > 0) {
      lines.forEach((item: CartLine) => {
        if (item.sellingPlanAllocation) {
          autoShipTotal += parseFloat(item.cost.totalAmount.amount);
        } else {
          oneTimeTotal += parseFloat(item.cost.totalAmount.amount);
        }

        if (item.discountAllocations && item.discountAllocations.length > 0) {
          discountTotal += item.discountAllocations
            .map((discount) => parseFloat(discount.discountedAmount.amount))
            .reduce((acc, curr) => acc + curr, 0);
        }
      });
    }
    setOneTimeTotal(oneTimeTotal);
    setAutoShipTotal(autoShipTotal);
    setCartSubtotal(oneTimeTotal + autoShipTotal);
    setDiscountTotal(discountTotal);
    const discountInfo =
      couponCodes && couponCodes.length > 0
        ? `&discount=${couponCodes.join("&discount=")}`
        : "";
    setCheckoutLink(
      `${cart.checkoutUrl}${discountInfo}&builder.overrideSessionId=${builder.sessionId}`
    );
  };

  useEffect(() => {
    if (cart.lines) {
      computeOneTimeAndAutoShipTotals(cart.lines);
    }
  }, [cart.lines]);

  return (
    <div
      className={`${styles.cartFooter} ${showUpsell ? styles.showUpsell : ""}`}
    >
      <div className={styles.cartTotalCheckout}>
        <div className={`${styles.cartFooterSection} ${styles.cartTotal}`}>
          <div className={styles.totalSection}>
            <span>Auto-Ship Purchases:</span>
            <p className={styles.subTotal}>${formatPrice(autoShipTotal)}</p>
          </div>
          <div className={styles.totalSection}>
            <span>One-Time Purchases:</span>
            <p className={styles.subTotal}>${formatPrice(oneTimeTotal)}</p>
          </div>
          {discountTotal > 0 && (
            <div className={styles.totalSection}>
              <span>
                Discount (
                {couponCodes &&
                  couponCodes.map((code) => code.toUpperCase()).join(", ")}
                ):{" "}
              </span>
              <p className={styles.subTotal}>-${formatPrice(discountTotal)}</p>
            </div>
          )}
          <div className={`${styles.totalSection} ${styles.cartSubTotal}`}>
            <span>Cart Total: </span>
            <p className={styles.subTotal}>
              {discountTotal > 0 && (
                <span className={styles.totalDiscount}>
                  ${formatPrice(cartSubtotal + discountTotal)}
                </span>
              )}{" "}
              ${formatPrice(cartSubtotal)}
            </p>
          </div>
        </div>

        <div className={`${styles.cartFooterSection} ${styles.cartCheckout}`}>
          <Button
            handleClick={() =>
              checkoutLink ? (location.href = checkoutLink) : null
            }
            text="Checkout"
            style={{
              width: "100%",
              backgroundColor: "#F4E04D",
              color: "#25273c",
            }}
            isActive={cart.lines && cart.lines.length > 0 ? true : false}
          />
          <p className={styles.cartCheckoutP}>
            {" "}
            Taxes and shipping calculated at checkout{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
