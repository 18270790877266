import {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { builder } from "@builder.io/sdk";
import { useCart } from "@shopify/hydrogen-react";
import { AuthContext } from "./auth.context";
import { getState, saveState, removeState } from "@lib/state.management.ts";
import { getRootSections } from "@services/builderIO";
import { trackAddToCart } from "@lib/analytics";

import SimpleLayout from "@src/layouts/simple";
import StoreLayout from "@src/layouts/store";
import { cartItemTiers } from "@services/shopify/product.data";
import type { CartLineUpdate } from "@services/shopify/mutations";
import type { PromoProps } from "@src/types/promo.type";
import type { Product } from "@src/types/product.type";
import type {
  BaseCartLine,
  CartLineInput,
  CartInput,
  AttributeInput,
  CartLine,
} from "@shopify/hydrogen-react/storefront-api-types";

const NEXT_PUBLIC_BUILDER_API_KEY = process.env.NEXT_PUBLIC_BUILDER_API_KEY;
builder.init(NEXT_PUBLIC_BUILDER_API_KEY!);

type StoreContextProps = {
  cartOpen: boolean;
  setCartOpen: Dispatch<SetStateAction<boolean>>;
  emptyCart: () => Promise<void>;
  addManyToCart: (
    lineItems: {
      product: Product;
      sellingPlanId?: string;
      quantity?: number;
    }[],
    cart: {
      checkoutUrl?: string;
      lines?: BaseCartLine[];
      discountCodes?: string[];
    } | null,
    openCart: boolean
  ) => Promise<void>;
  hasOrdered: boolean;
  promobarContent: PromoProps[];
  setPromoBarContent: Dispatch<SetStateAction<PromoProps[]>>;
  setHasOrdered: Dispatch<SetStateAction<boolean>>;
  setCoupon: (couponCode?: string) => Promise<void>;
  clearCoupons: () => Promise<void>;
  handleAddToCart: (
    lineItem: Product,
    sellingPlanId?: string | undefined,
    quantity?: number
  ) => void;
  handleChangeCartQuantity: (
    lineItem: Product,
    sellingPlanId?: string | undefined,
    quantity?: number
  ) => void;
  couponCodes: Array<string>;
  setCouponCodes: Dispatch<SetStateAction<Array<string>>>;
};

const StoreContext = createContext<StoreContextProps | undefined>(undefined);

export default function StoreProvider({
  props,
  children,
}: {
  props: any;
  children: ReactNode;
}) {
  const cartActions: any = useCart();
  const { account_loaded, customer_account } = useContext(AuthContext);

  // HEADER - FOOTER //
  const [header, setHeader] = useState(
    props.sections ? props.sections["header"] : null
  );
  const [footer, setFooter] = useState(
    props.sections ? props.sections["footer"] : null
  );
  const [hasOrdered, setHasOrdered] = useState(false);
  const [promobarContent, setPromoBarContent] = useState<PromoProps[]>([]);
  const [cartOpen, setCartOpen] = useState(false);
  const [gotoCheckout, setGotoCheckout] = useState(false);

  // COUPONS //
  const [couponCodes, setCouponCodes] = useState<Array<string>>([]);

  // SIMPLE QUE //
  const [simpleQue, setSimpleQue] = useState<any>([]);

  // SECTIONS CONTENT //
  if (!props.sections && !header && !footer) {
    getRootSections([]).then((sections) => {
      setHeader(sections["header"]);
      setFooter(sections["footer"]);
    });
  }

  /**
   *
   * @param items - An array of items to be added to the cart; the function will check if the item is already in the cart and update the quantity accordingly
   * @returns
   */
  const reduceItems = (
    items: {
      lineItem: {
        product: Product;
        sellingPlanId?: string;
        quantity?: number;
      };
      cartItem: BaseCartLine | undefined;
    }[]
  ) => {
    return items.map<
      | { type: "new"; data: CartLineInput }
      | { type: "update"; data: CartLineUpdate }
      | { type: "remove"; data: string }
    >((x) => {
      if (!x.cartItem) {
        return {
          type: "new",
          data: {
            merchandiseId: `gid://shopify/ProductVariant/${x.lineItem.product.vid}`,
            quantity: x.lineItem.quantity ?? 0,
            ...(x.lineItem.sellingPlanId
              ? { sellingPlanId: x.lineItem.sellingPlanId }
              : { sellingPlanId: null }),
          },
        };
      }

      if (x.cartItem && x.lineItem.quantity && x.lineItem.quantity === 0) {
        return {
          type: "remove",
          data: x.cartItem.id,
        };
      }

      const newQuantity =
        (x.cartItem?.quantity ?? 0) + (x.lineItem.quantity ?? 0);

      let newVariantId = x.lineItem.product.tiers
        ? x.lineItem.product.tiers
            .filter((x) => x.tier <= newQuantity)
            .sort((a, b) => a.tier - b.tier)
            .reverse()[0].vid
        : x.lineItem.product.variant?.vid ?? x.lineItem.product.vid;

      return {
        type: "update",
        data: {
          id: x.cartItem.id,
          merchandiseId: `gid://shopify/ProductVariant/${newVariantId}`,
          quantity: newQuantity,
          ...(x.lineItem.sellingPlanId
            ? { sellingPlanId: x.lineItem.sellingPlanId }
            : { sellingPlanId: null }),
        },
      };
    });
  };

  /**
   *
   * @param linesItems - An array of items to be added to the cart; the function will check if the item is already in the cart and update the quantity accordingly
   * @returns
   */
  const handleExistingCartUpdate = async (
    linesItems: (
      | { type: "new"; data: CartLineInput }
      | {
          type: "update";
          data: CartLineUpdate;
        }
      | { type: "remove"; data: string }
    )[]
  ) => {
    // Get Cart Operation
    //  - Here, we get teh cart ID from local storage and check if the cart exists
    const cartId = getState("cartId");
    const que = simpleQue;

    if (!cartActions.id && !cartId) return;

    // New Lines Operation
    //  - Filter for all the new lines that need to be added to the cart
    const newLines = linesItems
      .filter((x) => x.type == "new")
      .map((x) => x.data as CartLineInput);

    //  - If there are new lineItems, add them to the cart
    if (newLines.length > 0) {
      que.push(cartActions.linesAdd(newLines));
    }

    // Update Lines Operation
    //  - Filter for all the lines that need to be updated in the cart
    //  - If there are lines that need to be updated, update them in the cart
    const updateLines = linesItems
      .filter((x) => x.type === "update")
      .map((x) => x.data as CartLineUpdate);

    if (updateLines.length > 0) {
      que.push(cartActions.linesUpdate(updateLines));
    }

    // Remove Lines Operation
    //  - Here, we filter for all the lines that need to be removed from the cart
    //  - If there are lines that need to be removed, remove them from the cart
    const removeLines = linesItems
      .filter((x) => x.type === "remove")
      .map((x) => x.data as string);

    if (removeLines.length > 0) {
      que.push(cartActions.linesRemove(removeLines));
    }

    // Check for auto-ships
    const autoShip = linesItems.filter(
      (x) => x.type !== "remove" && x.data.sellingPlanId
    );
    const _attributes: AttributeInput[] = autoShip
      ? [{ key: "Autoship", value: "True" }]
      : [];

    que.push(cartActions.cartAttributesUpdate(_attributes));
    que.push(() => {
      setCoupon();
    });
    setSimpleQue(que);
  };

  const handleAddToCart = async (
    lineItem: Product,
    sellingPlanId: string | undefined = undefined,
    quantity?: number
  ) => {
    if (cartActions.status === "uninitialized") {
      cartActions.cartCreate({
        attributes: sellingPlanId ? [{ key: "Autoship", value: "True" }] : [],
        lines: [
          {
            merchandiseId: `gid://shopify/ProductVariant/${
              lineItem.variant?.vid ?? lineItem.id
            }`,
            quantity: quantity ?? 1,
            sellingPlanId: sellingPlanId,
          },
        ],
      });
      // Set Coupons if they exist
      setTimeout(() => {
        setCoupon();
      }, 1200);
    } else {
      // Matching Operation
      //  - Here, we call findMatchingCartItem which will return an array of cart items that match the lineItem and its' sellingPlanId
      const matchingCartItem = findMatchingCartItem(
        cartActions.lines,
        lineItem
      );

      // Reduce Items Operation
      //  - Here, we call reduceItems which will return an array of items that need to be updated in the cart
      const lineItems = reduceItems([
        {
          cartItem: matchingCartItem,
          lineItem: {
            product: lineItem,
            quantity: quantity ?? 1,
            sellingPlanId: sellingPlanId,
          },
        },
      ]);
      handleExistingCartUpdate(lineItems);
    }

    trackAddToCart([lineItem], cartActions.lines);
    setCartOpen(true);
  };

  const addManyToCart = async (
    lineItems: {
      product: Product;
      sellingPlanId?: string;
      quantity?: number;
    }[],
    cart: {
      checkoutUrl?: string;
      lines?: BaseCartLine[];
      discountCodes?: string[];
    } | null = null,
    openCart = true
  ) => {
    setGotoCheckout(!openCart);
    if (!cart) {
      cartActions.cartCreate({
        attributes:
          lineItems.filter((item) => item.sellingPlanId).length > 0
            ? [{ key: "Autoship", value: "True" }]
            : [],
        lines: lineItems.map((item) => ({
          merchandiseId: `gid://shopify/ProductVariant/${
            item.product.variant?.vid ?? item.product.vid
          }`,
          quantity: item.quantity ?? 1,
          sellingPlanId: item.sellingPlanId ?? undefined,
        })),
      });
    } else {
      const _cart = cart.lines || [];
      const matchedItems = lineItems.map((lineItem) => {
        const matchingCartItem = findMatchingCartItem(_cart, lineItem.product);
        if (matchingCartItem) {
          _cart.splice(
            _cart.findIndex(
              (y: any) => y.merchandise.id === matchingCartItem.merchandise.id
            ),
            1
          );
        }

        return {
          lineItem: {
            product: lineItem.product,
            quantity: lineItem.quantity ?? 1,
            sellingPlanId: lineItem.sellingPlanId,
          },
          cartItem: matchingCartItem,
        };
      });
      const reduced = reduceItems(matchedItems);
      handleExistingCartUpdate(reduced);
    }

    trackAddToCart(lineItems, cart?.lines || []);
    setCartOpen(openCart);
  };

  const proceedToCheckout = () => {
    const _checkoutUrl = cartActions.checkoutUrl;
    if (_checkoutUrl) {
      const storedCouponCodes = couponCodes;
      const discountInfo: string =
        storedCouponCodes.length > 0
          ? `&discount=${storedCouponCodes.join("&discount=")}`
          : "";
      const checkout = `${_checkoutUrl}${discountInfo}&builder.overrideSessionId=${builder.sessionId}`;
      window.location.replace(checkout);
    } else {
      setCartOpen(true);
    }
  };

  /**
   *
   * @param { Product } lineItem - The product that we want to update the quantity for
   * @param { string } sellingPlanId - The selling plan of the item that we can to update the quantity for
   * @param { number } newQuanity - The quantity by which we want to change the item
   * @returns
   */
  const handleChangeCartQuantity = async (
    lineItem: Product,
    sellingPlanId: string | undefined = undefined,
    newQuanity?: number
  ) => {
    // Cart Operations
    if (!cartActions.id) return;

    // Line Item Operation
    //  - Here, we call the cartItemTiers function on lineItem (the product to add) which does the important action of adding in tiers to the product
    lineItem = cartItemTiers(lineItem);

    // Matching Operation
    //  - Here, we call findMatchingCartItem which will return an array of cart items that match the lineItem and its' sellingPlanId
    const matchingCartItem = findMatchingCartItem(cartActions.lines, lineItem);

    // Reduce Items Operation
    //  - Here, we call reduceItems which will return an array of items that need to be updated in the cart
    const lineItems = reduceItems([
      {
        cartItem: matchingCartItem,
        lineItem: {
          product: lineItem,
          ...{ quantity: newQuanity ?? 1 },
          ...(sellingPlanId ? { sellingPlanId: sellingPlanId } : {}),
        },
      },
    ]);

    // Handle Existing Cart Update Operation
    // - Here, we call handleExistingCartUpdate which will update the cart with the new lineItems
    handleExistingCartUpdate(lineItems);
  };

  const emptyCart = async () => {
    removeState("cartId");
    removeState("kpc_cart");
    removeState("checkoutUrl");
    if (!cartActions.id) return;
    cartActions.linesRemove(cartActions.lines.map((x: any) => x.id));
  };

  // --- Cart Helper Methods --- //
  function findMatchingCartItem(cartLines: BaseCartLine[], lineItem: Product) {
    if (!cartLines) return;
    return lineItem.tiers
      ? cartLines.find((y) => y.merchandise.product.id == lineItem.id)
      : cartLines.find(
          (y) =>
            y.merchandise.id ==
              `gid://shopify/ProductVariant/${
                lineItem.variant?.vid ?? lineItem.vid ?? lineItem.id
              }` || y.merchandise.id === lineItem.id
        );
  }

  // --- [END] Cart Methods --- [END] //

  // Coupons
  /**
   *
   * @param couponCode - The coupon code to apply to the cart; if the coupon code is not already applied, it will be added to the list of applied coupon codes.
   * The function stores the coupon information to local storage so that the state can persists.
   *
   * Note: This function will eventually be updated so that can interact with the Shopify API and apply the coupon code to the cart
   */
  const setCoupon = async (couponCode?: string) => {
    let discountCodes = couponCodes;
    if (couponCode && !couponCodes.includes(couponCode)) {
      setCouponCodes((prev) => [...prev, couponCode]);
      discountCodes = [...couponCodes, couponCode];
    }
    saveState("coupon_codes", JSON.stringify(discountCodes));
    const cartId = getState("cartId");
    if (cartId && discountCodes) {
      cartActions.discountCodesUpdate(discountCodes);
    }
  };

  const clearCoupons = async () => {
    setCouponCodes([]);
    removeState("coupon_codes");
    removeState("promoCouponApplied");
  };

  // UseEffect

  // This UseEffect call needs to be removed and/or updated as it utilizes a call to Recurly
  useEffect(() => {
    if (customer_account && customer_account.numberOfOrders >= 1) {
      setHasOrdered(true);
      clearCoupons();
    }
  }, [customer_account, account_loaded]);

  useEffect(() => {
    // Check if the cart is uninitialized if so, remove the cartId and kpc_cart from local storage
    if (cartActions.status === "uninitialized") {
      emptyCart();
    }

    const storedCouponCodes: Array<string> = JSON.parse(
      getState("coupon_codes") || "[]"
    );
    if (storedCouponCodes.length > 0) {
      setCouponCodes(storedCouponCodes);
    }
  }, []);

  useEffect(() => {
    if (cartActions.lines && cartActions.id && cartActions.status === "idle") {
      saveState("cartId", cartActions.id);
      saveState("kpc_cart", cartActions.lines);
      saveState("checkoutUrl", cartActions.checkoutUrl);
      if (gotoCheckout) {
        proceedToCheckout();
      }
    }

    if (cartActions.status === "idle" && simpleQue.length > 0) {
      const simpleQueCopy = [...simpleQue];
      const q = simpleQueCopy.shift();
      if (q) q();
      setSimpleQue(simpleQueCopy);
    }
  }, [cartActions.lines, cartActions.status, simpleQue, gotoCheckout]);

  return (
    <StoreContext.Provider
      value={{
        cartOpen,
        emptyCart,
        setCartOpen,
        addManyToCart,
        hasOrdered,
        promobarContent,
        setPromoBarContent,
        setHasOrdered,
        setCoupon,
        clearCoupons,
        setCouponCodes,
        couponCodes,
        handleAddToCart,
        handleChangeCartQuantity,
      }}
    >
      {props.header_simple ? (
        <SimpleLayout>{children}</SimpleLayout>
      ) : (
        <StoreLayout
          header={header}
          footer={footer}
          cartItems={cartActions.totalQuantity}
        >
          {children}
        </StoreLayout>
      )}
    </StoreContext.Provider>
  );
}

const StoreConsumer = StoreContext.Consumer;

export { StoreConsumer, StoreContext };
