import { useContext } from "react";
import { StoreContext } from "@context/store.context";
import { ProductContextV2 } from "@context/storefront.product.context";
import Button from "@components/elements/button.component";
import styles from "@styles/components/cart/cart-upsell-item.module.scss";

export default function CartUpsellItem() {
  const { handleAddToCart } = useContext(StoreContext);
  const { productData } = useContext(ProductContextV2);
  const handleUpsellClick = (e) => {
    e.preventDefault();
    handleAddToCart(productData);
  };

  return (
    <div className={styles.cartUpsellItem} onClick={handleUpsellClick}>
      <div className={styles.cartUpsellItemImg}>
        <div className={styles.cartUpsellImgWrapper}>
          <img src={productData.image} />
        </div>
      </div>
      <h4 className={styles.upsellTitle}>{productData.title}</h4>
      <p className={styles.upsellPrice}>${productData.price}</p>
      <Button
        text={`$${productData.price} - Add to Cart`}
        className={styles.upsellBtn}
      />
    </div>
  );
}
