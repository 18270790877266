import { createContext, useState, useEffect, ReactNode } from "react";
import { getCookie, deleteCookie, CookieValueTypes } from "cookies-next";
import { useShopifyCookies } from "@shopify/hydrogen-react";
import { getState, saveState } from "@lib/state.management.ts";
import { tryPostApi } from "@lib/fetch-helpers";
import type { CustomerAccount } from "@src/types/customer.type";

interface AuthContextProps {
  account_loaded: boolean;
  customer_account: CustomerAccount | undefined;
}

const AuthContext = createContext<AuthContextProps>({
  account_loaded: false,
  customer_account: undefined,
});

export default function AuthProvider({ children }: { children: ReactNode }) {
  const [customer_account, setAccount] = useState<any | undefined>(undefined);
  const [account_loaded, setAccountLoaded] = useState(false);

  const handleRequest = async (token: string | true) => {
    if (!account_loaded) {
      await tryPostApi("/api/store/customer", {
        options: token,
      }).then((resp) => {
        if (resp && resp.id) {
          let _account = {
            ...resp,
            numberOfOrders: +resp.numberOfOrders,
            account_code: resp.id.split("/").pop(),
          };
          setAccount(_account);
          saveState("kpc_customer", _account);
          setAccountLoaded(true);
        }
      });
    }
  };

  useEffect(() => {
    // FROM MAIN SITE //
    // const cookie = getShopifyCookies(document.cookie);
    const _token: CookieValueTypes = getCookie("secure_customer_sig");
    const _saved_customer = getState("kpc_customer");
    if (_saved_customer && _token) {
      setAccount(_saved_customer);
      setAccountLoaded(true);
    } else if (_token) {
      handleRequest(_token);
    }
  }, []);

  useShopifyCookies({ hasUserConsent: true });

  return (
    <AuthContext.Provider
      value={{
        account_loaded,
        customer_account,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const AuthConsumer = AuthContext.Consumer;

export { AuthConsumer, AuthContext };
