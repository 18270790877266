import { createStorefrontClient } from "@shopify/hydrogen-react";
import shopifyConfig from "@config/shopify";

const client = createStorefrontClient({
  storeDomain: shopifyConfig.storeDomain!,
  storefrontApiVersion: shopifyConfig.storefrontApiVersion!,
  privateStorefrontToken: shopifyConfig.storeAdminToken,
});

export default async function shopifyConnect(GRAPHQL_QUERY: string) {
  const response = await fetch(client.getStorefrontApiUrl(), {
    body: JSON.stringify({
      query: GRAPHQL_QUERY,
    }),
    headers: client.getPrivateTokenHeaders(),
    method: "POST",
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return await response.json();
}
