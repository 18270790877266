export const customer_login = (e, p) => {
  return `mutation {
    customerAccessTokenCreate (input: {
        email: "${e}",
        password: "${p}"
      }
    )
    {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }`;
};

export const multiPass = (token) => {
  return `mutation {
    customerAccessTokenCreateWithMultipass(multipassToken: "${token}") {
      customerAccessToken {
        accessToken
      }
      customerUserErrors {
        code
      }
    }
  }
`;
};

export const customerCreate = (options) => {
  const _phone = options.phone ? `phone: "${options.phone}",` : "";
  return `mutation {
    customerCreate(input: {
      acceptsMarketing: ${options.acceptsMarketing},
      email: "${options.email}",
      firstName: "${options.firstName}",
      lastName: "${options.lastName}",
      password: "${options.password}",
      ${_phone}
    }) {
      customer {
        id
        firstName
        lastName
        email
        phone
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }`;
};

export const customerUpdate = (customer, token) => {
  const _phone = customer.phone ? `phone: "${customer.phone}",` : "";
  return `mutation {
    customerUpdate(
      customer: {
        firstName: "${customer.profileFirstName}",
        lastName: "${customer.profileLastName}",
        email: "${customer.email}",
        ${_phone}
      }, 
      customerAccessToken: "${token}") {
      customerUserErrors {
        code,
        message
      }
    }
  }`;
};

export const customerAddressUpdate = (id, address, token) => {
  return `mutation {
    customerAddressUpdate(
      address: {
        firstName: "${address.firstName}",
        lastName: "${address.lastName}",
        address1: "${address.address1}",
        address2: "${address.address2}",
        city: "${address.city}",
        country: "${address.countryCode}",
        province: "${address.provinceCode}",
        zip: "${address.zip}",
      }, customerAccessToken: "${token}", 
      id: "${id}") {
      customerUserErrors {
        code,
        message
      }
    }
  }`;
};

export const customerAddressCreate = (options) => {
  return `mutation {
    customerAddressCreate(address: {
        firstName: "${options.address.firstName}",
        lastName: "${options.address.lastName}",
        address1: "${options.address.address1}",
        address2: "${options.address.address2}",
        city: "${options.address.city}",
        country: "${options.address.countryCode}",
        phone: "${options.address.phone}",
        province: "${options.address.provinceCode}",
        zip: "${options.address.zip}"
      }, customerAccessToken: "${options.token}") {
      customerUserErrors {
        code,
        message
      }
    }
  }`;
};

export const activate_customer = (id, token, password) => {
  return `mutation {
    customerActivate(id: "gid://shopify/Customer/${id}" input: {activationToken: "${token}", password: "${password}"}) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        message
      }
    }
  }`;
};

export const request_reset = (email) => {
  return `mutation {
    customerRecover (email: "${email}")
    {
      customerUserErrors{
        message
      }
    }
  }`;
};

export const restore = (id, p, t) => {
  return `mutation {
    customerReset(id: "gid://shopify/Customer/${id}", input: {password: "${p}", resetToken: "${t}"}) {
      customerUserErrors{
        message
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
    }
  }`;
};

export const customer = (token) => {
  return `{
    customer(customerAccessToken: "${token}") {
      id
      firstName
      lastName
      email
      phone
      tags
      acceptsMarketing
      numberOfOrders
      defaultAddress {
        id
        firstName
        lastName
        address1
        address2
        city
        countryCode
        phone
        provinceCode
        zip
      }
    }
  }`;
};

export const tracking = (id) => {
  return `
  {
    customer(id: "${id}") {
      orders(first: 3, reverse: true) {
        edges {
          node {
            fulfillments {
              displayStatus
              deliveredAt
              trackingInfo {
                company
                number
                url
              }
            }
            note
          }
        }
      }
    }
  }
  `;
};

export const shadow = (id) => {
  return `{
    customer(id: "gid://shopify/Customer/${id}") {
      id
      firstName
      lastName
      emailMarketingConsent {
        consentUpdatedAt
        marketingOptInLevel
      }
      email
      phone
      tags
      numberOfOrders
      lifetimeDuration
      amountSpent {
        amount
      }
      defaultAddress {
        id
        firstName
        lastName
        address1
        address2
        city
        countryCodeV2
        phone
        provinceCode
        zip
      }
      orders(first: 1, reverse: true) {
        edges {
          node {
            fulfillments(first: 50) {
              displayStatus
              deliveredAt
              trackingInfo {
                company
                number
                url
              }
            }
          }
        }
      }
    }
  }`;
};

// PRODUCTS //
const productMeta = `
  meta_sectionHeadline: metafield(namespace:"custom_fields", key:"accordions_headline") {
    value
  }
  meta_accordion0xheadline: metafield(namespace:"custom_fields", key:"accordion_1_headline") {
    value
  }
  meta_accordion0xcontent: metafield(namespace:"custom_fields", key:"accordion_1_content") {
    value
  }
  meta_accordion0xhtml: metafield(namespace:"custom_fields", key:"accordion_1_content_html") {
    value
  }
  meta_accordion1xheadline: metafield(namespace:"custom_fields", key:"accordion_2_headline") {
    value
  }
  meta_accordion1xcontent: metafield(namespace:"custom_fields", key:"accordion_2_content") {
    value
  }
  meta_accordion1xhtml: metafield(namespace:"custom_fields", key:"accordion_2_content_html") {
    value
  }
  meta_accordion2xheadline: metafield(namespace:"custom_fields", key:"accordion_3_headline") {
    value
  }
  meta_accordion2xcontent: metafield(namespace:"custom_fields", key:"accordion_3_content") {
    value
  }
  meta_accordion2xhtml: metafield(namespace:"custom_fields", key:"accordion_3_content_html") {
    value
  }
  meta_detail1: metafield(namespace:"custom_fields", key:"detail_1") {
    value
  }
  meta_detail2: metafield(namespace:"custom_fields", key:"detail_2") {
    value
  }
  meta_detail3: metafield(namespace:"custom_fields", key:"detail_3") {
    value
  }
  meta_detail4: metafield(namespace:"custom_fields", key:"detail_4") {
    value
  }
  compare_items: metafield(namespace:"custom", key:"compare_similar") {
    value
  }
  compare_data: metafield(namespace:"custom", key:"compare_fields") {
    value
  }
  meta_complementaryProducts: metafield(namespace: "shopify--discovery--product_recommendation", key: "complementary_products") {
    value
  }
  meta_defaultSellingPlan: metafield(namespace: "custom", key: "default_selling_plan") {
    value
  }
  meta_useDefaultSellingPlanOnPDP: metafield(namespace: "custom", key: "use_default_selling_plan_on_pdp") {
    value
  }
  `;

/*

  meta_reviewBottemLine: metafield(namespace:"yotpo", key:"bottomline") {
    value
  }
  meta_reviews: metafield(namespace:"yotpo_reviews", key:"1000") {
    value
  }
  */

const basicProductFields = `
  title
  handle
  id
  description
  featuredImage {
    url(transform: {maxWidth: 280, maxHeight: 280})
    altText
  }
  options {
    name
  }
  priceRange {
    maxVariantPrice {
      amount
    }
    minVariantPrice {
      amount
    }
  }
  productType
  seo {
    title
  }
  tags
  totalInventory
  variants(first: 10) {
    edges {
      node {
        title
        id
        sku
        availableForSale
        currentlyNotInStock
        quantityAvailable
        price {
          amount
        }
        compareAtPrice {
          amount
        }
        selectedOptions {
          name
          value
        }
        
      }
    }
  }
  vendor
`;

export const sellingPlanFields = `
  name
  id
  description
  recurringDeliveries
  priceAdjustments {
    adjustmentValue {
      ... on SellingPlanFixedAmountPriceAdjustment {
        __typename
        adjustmentAmount {
          amount
          currencyCode
        }
      }
      ... on SellingPlanFixedPriceAdjustment {
        __typename
        price {
          amount
          currencyCode
        }
      }
      ... on SellingPlanPercentagePriceAdjustment {
        __typename
        adjustmentPercentage
      }
    }
    orderCount
  }`;

export const expandedProductFields = `
  ${basicProductFields}
  descriptionHtml
  images(first: 1) {
    edges {
      node {
        altText
        url(transform: {maxWidth: 280})
      }
    }
  }
  media(first: 10) {
    edges {
      node {
        previewImage {
          altText
          url(transform: {maxWidth: 280})
        }
        mediaContentType
        ... on Video {
          sources {
            format
            height
            mimeType
            url
            width
          }
          
        }
        ... on MediaImage {
          image {
            altText
            url(transform: {maxHeight: 400})
            lrg: url
          }
        }
        ... on ExternalVideo {
          embedUrl
          originUrl
        }
      }
    }
  }
  sellingPlanGroups(first: 10) {
    edges {
      node {
        name
        sellingPlans(first: 50) {
          edges {
            node {
              ${sellingPlanFields}
            }
          }
        }
      }
    }
  }
  ${productMeta}
  collections(first: 5) {
    edges {
      node {
        handle
        title
        menuItem: metafield(namespace:"custom", key:"in_navigation") {
          value
        }
      }
    }
  }
`;

export const productByHandle = (handle) => {
  return `{
    product(handle: "${handle}") {
      ${expandedProductFields}
    }
  }`;
};

export const product = (pid) => {
  return `{
    product(id: "gid://shopify/Product/${pid}") {
      ${expandedProductFields}
    }
  }`;
};

export const productTypeById = (pid) => {
  return `{
    product(id: "gid://shopify/Product/${pid}") {
      productType
    }
  }`;
};

export const complementaryProduct = (pid) => {
  return `{
    product(id: "${pid}") {
      id
      handle
      title
      featuredImage {
        url(transform: {maxWidth: 280, maxHeight: 280})
      }
      priceRange {
        minVariantPrice {
          amount
        }
      }
    }
  }`;
};

const productsForCollections = `
  edges {
    node {
      ${basicProductFields}
      collections(first: 5) {
        edges {
          node {
            handle
            title
            menuItem: metafield(namespace:"custom", key:"in_navigation") {
              value
            }
          }
        }
      }
      hidden: metafield(namespace:"custom", key:"hidden") {
        value
      }
      defaultSellingPlan: metafield(namespace: "custom", key: "default_selling_plan") {
        value
      } 
    }
    cursor
  }
`;

export const filterProducts = (
  term,
  size = 12,
  sort,
  reverse = false,
  cursor = false
) => {
  const query = term ? `, query: "${term}"` : "";
  const sortKey = sort ? `, sortKey: ${sort}, reverse: ${reverse}` : "";
  const _cursor = cursor ? `, after: "${cursor}"` : "";
  return `{
    products(first: ${size} ${_cursor} ${query} ${sortKey}) {
      ${productsForCollections}
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }`;
};

export const productRecommendations = (pid) => {
  return `{
    productRecommendations(productId: "${pid}"){
      ${basicProductFields}
      hidden: metafield(namespace:"custom", key:"hidden") {
        value
      }
    }
  }`;
};

// COLLECTIONS //
export const collections = () => {
  return `{
    collections(first: 200, sortKey: TITLE) {
      edges {
        node {
          id
          handle
          title
          hidden: metafield(namespace:"custom", key:"hidden") {
            value
          }
          showFilters: metafield(namespace:"custom" key:"show_filters") {
            value
          }
          menuItem: metafield(namespace:"custom", key:"in_navigation") {
            value
          }
          popularBrands: metafield(namespace:"custom_fields" key:"enable_popular_brands") {
            value
          }
          products(first: 1) {
            edges {
              node {
                featuredImage {
                  url(transform: {maxWidth: 280, maxHeight: 280})
                }
              }
            }
            pageInfo {
              endCursor
            }
          }
        }
      }
    }
  }`;
};

export const collectionByHandle = (
  handle,
  sort,
  reverse = false,
  to = 12,
  cursor,
  vendors = []
) => {
  const _filter =
    vendors.length > 0
      ? `filters: [${vendors.map((vendor) => `{productVendor : "${vendor}"}`)}]`
      : "filters: {available: true}";
  const _cursor = cursor ? `, after: "${cursor}"` : "";
  const sortKey = sort ? `, sortKey: ${sort}, reverse: ${reverse}` : "";

  return `{
    collection(handle: "${handle}") {
      title
      handle
      description
      image {
        altText
        url(transform: {maxHeight: 400})  
      }
      hidden: metafield(namespace:"custom", key:"hidden") {
        value
      }
      showComparison: metafield(namespace:"custom" key:"show_litter_comparison") {
        value
      }
      showFilters: metafield(namespace:"custom" key:"show_filters") {
        value
      }
      popularBrands: metafield(namespace:"custom_fields" key:"enable_popular_brands") {
        value
      }
      products(first: ${to} ${_cursor} ${sortKey}, ${_filter} ) {
        filters {
          id
          label
          type
          values {
            id
            label
            count
            input
          }
        }
        ${productsForCollections}
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }`;
};

export const collectionFilters = (type, vendor) => {
  const _filter = vendor ? `, filters: {productVendor: "${vendor}"}` : "";
  return `{
    collection(handle: "${type}") {
      products(first: 1 ${_filter}) {
        filters {
          id
          label
          type
          values {
            id
            label
            count
            input
          }
        }
      }
    }
  }`;
};

export const collection = (handle) => {
  return `{
    collection(handle: "${handle}") {
      title
      products(first: 50) {
        ${productsForCollections}
      }
    }
  }`;
};

/*
export const simpleCartList = `
  id
  subtotalPrice {
    amount
  }
  totalPrice {
    amount
  }
  lineItems(first: 250) {
    edges {
      node {
        id
        title
        quantity
        customAttributes {
          key
          value
        }
        variant {
          id
          sku
          price {
            amount
          }
          product {
            ${basicProductFields}
          }
          image {
            id,
            transformedSrc
          }
        }
      }
    }
  }
`;

export const cart = (cid) => {
  return `{
    node(
      id: "${cid}"
    ) {
      id
      ... on Checkout {
        ${simpleCartList}
      }
    }
  }
  `;
};
*/

export const predictiveSearch = (term) => {
  return `
  {
    predictiveSearch(query: "${term}", limitScope: ALL, limit: 10) {
      queries {
        text
      }
      collections {
        id,
        onlineStoreUrl
      }
      products {
        ${basicProductFields}
      }
    }
  }
  `;
};

export const simpleSearch = (query, to = 12, cursor) => {
  return `
  {
    search(query: "${query}", first: ${to}, types: PRODUCT, unavailableProducts: HIDE ${
    cursor ? `, after: "${cursor}"` : ""
  }) {
      edges {
        node {
          ... on Product {
            ${basicProductFields}
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  `;
};

export const cartDrawerFragment = `
fragment CartFragment on Cart {
  id
  checkoutUrl
  discountAllocations {
    discountedAmount {
      amount
      currencyCode
    }
  }
  totalQuantity
  buyerIdentity {
    countryCode
    customer {
      id
      email
      firstName
      lastName
      displayName
    }
    email
    phone
  }
  lines(first: $numCartLines) {
    edges {
      node {
        id
        quantity
        attributes {
          key
          value
        }
        cost {
          totalAmount {
            amount
            currencyCode
          }
          compareAtAmountPerQuantity {
            amount
            currencyCode
          }
        }
        merchandise {
          ... on ProductVariant {
            id
            availableForSale
            compareAtPrice {
              ...MoneyFragment
            }
            price {
              ...MoneyFragment
            }
            requiresShipping
            title
            image {
              ...ImageFragment
            }
            product {
              ...product
            }
            selectedOptions {
              name
              value
            }
          }
        }
        discountAllocations {
          discountedAmount {
            amount
            currencyCode
          }
        }
        sellingPlanAllocation {
          sellingPlan {
            ${sellingPlanFields}
          }
        }
      }
    }
  }
  cost {
    subtotalAmount {
      ...MoneyFragment
    }
    totalAmount {
      ...MoneyFragment
    }
    totalDutyAmount {
      ...MoneyFragment
    }
    totalTaxAmount {
      ...MoneyFragment
    }
  }
  note
  attributes {
    key
    value
  }
  discountCodes {
    code
    applicable
  }
}

fragment MoneyFragment on MoneyV2 {
  currencyCode
  amount
}
fragment ImageFragment on Image {
  id
  url
  altText
  width
  height
}

fragment product on Product {
  id,
  title,
  handle,
  productType,
  tags,
  options {
    name
  }
  variants(first: 10) {
    edges {
      node {
        title
        id
        price {
          amount
        }
        compareAtPrice {
          amount
        }
        selectedOptions {
          name
          value
        }
        
      }
    }
  }
  sellingPlanGroups(first: 10) {
    edges {
      node {
        name
        sellingPlans(first: 50) {
          edges {
            node {
              ${sellingPlanFields}
            }
          }
        }
      }
    }
  }
  meta_defaultSellingPlan: metafield(namespace: "custom", key: "default_selling_plan") {
    value
  }
}

`;

export const cartFragment = `
fragment cart on Cart {
    id
    checkoutUrl
    discountAllocations {
      discountedAmount {
        amount
        currencyCode
      }
    }
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
    }
    lines(first: 100) {
      edges {
        node {
          id
          quantity
          cost {
            totalAmount {
              amount
              currencyCode
            }
          }
          sellingPlanAllocation {
            sellingPlan {
              ${sellingPlanFields}
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              title
              selectedOptions {
                name
                value
              }
              product {
                ...product
              }
            }
          }
          discountAllocations {
            discountedAmount {
              amount
              currencyCode
            }
          }
        }
      }
    }
    totalQuantity
  }
  fragment product on Product {${expandedProductFields}}
`;

export const getCartQuery = `
  query getCart($id: ID!) {
    cart(id: $id) {
      ...cart
    }
  }
  ${cartFragment}
`;
