import { builder } from "@builder.io/react";

export const getBuilderStaticPaths = async (modelName: string) => {
  const results = await builder.getAll(modelName, {
    fields: "data.url",
    limit: 200,
    options: {
      noTargeting: true,
    },
  });

  const paths = results
    .filter(
      (item) =>
        item.data?.url !== "/" &&
        item.data?.url !== "/search" &&
        item.data?.url !== "/join-the-club"
    )
    .map((item) => ({
      params: { page: (item.data?.url?.replace("/", "") || "_").split("/") },
    }));

  return {
    paths,
    fallback: "blocking",
  };
};
