import shopifyConfig from "@config/shopify";

export default {
  apiKey: process.env.NEXT_PUBLIC_BUILDER_API_KEY!,
  productsModel: "shopify-product",
  product_links: "/products/",
  collectionsModel: "shopify-collection",
  collection_links: "/collections/",
  locale: "en-US",
  shopify: {
    storefrontAccessToken: shopifyConfig.storefrontToken,
    domain: shopifyConfig.storeDomain,
  },
};
