import { PropsWithChildren } from "react";
import { useContext, useState, useEffect } from "react";
import { BuilderComponent } from "@builder.io/react";
import Cart from "@components/cart/cart.component.tsx";
import { StoreContext } from "@context/store.context";
import { AuthContext } from "@context/auth.context";
import type { PromoBarProps } from "@src/types/promo.type";
import { renderLink } from "@services/builderIO";

interface LayoutProps extends PropsWithChildren<any> {
  [x: string]: any;
}

export default function SimpleLayout(props: LayoutProps) {
  const { children, ...pageProps } = props;
  const { hasOrdered, setPromoBarContent, setCoupon } =
    useContext(StoreContext)!;

  const { account_loaded } = useContext(AuthContext);
  const [loggedIn, setLoggedIn] = useState(account_loaded);
  const [readyToRecieve, setReadyToRecieve] = useState(false);
  const [padding, setPadding] = useState("180px");

  useEffect(() => {
    setReadyToRecieve(true);
  }, []);

  useEffect(() => {
    setLoggedIn(account_loaded);
  }, [account_loaded]);

  return (
    <>
      <BuilderComponent
        content={pageProps.header}
        model="header"
        data={{ hasOrdered, readyToRecieve, loggedIn }}
        context={{
          storePromos: (list: PromoBarProps) =>
            setPromoBarContent(list.promos ?? []),
          setCoupon: (couponCode: string) => setCoupon(couponCode),
          setPadding: (offset: number) => setPadding(`${offset}px`),
        }}
        renderLink={renderLink}
      />
      <Cart />
      <main id="main_component" style={{ marginTop: padding }}>
        {children}
        <BuilderComponent
          content={pageProps.footer}
          model="footer"
          renderLink={renderLink}
        />
      </main>
    </>
  );
}
