import { createContext, useState } from "react";

export interface CmsBridgeContextProps {
  state: any;
  setState: (data: any) => void;
}

interface CmsBridgeProviderProps {
  settings?: any;
  children?: React.ReactElement;
}

const CmsBridgeContext = createContext<CmsBridgeContextProps>({
  state: {} as CmsBridgeContextProps,
  setState: () => {},
});

export default function CmsBridgeProvider({
  settings,
  children,
}: CmsBridgeProviderProps) {
  const [state, setState] = useState({} as CmsBridgeContextProps);

  return (
    <CmsBridgeContext.Provider
      value={{
        ...settings,
        state,
        setState,
      }}
    >
      {children}
    </CmsBridgeContext.Provider>
  );
}

const CmsBridgeConsumer = CmsBridgeContext.Consumer;

export { CmsBridgeConsumer, CmsBridgeContext };
