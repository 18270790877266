import { builder } from "@builder.io/react";
import { GetStaticPropsContext } from "next";
import { getRootSections } from "@services/builderIO/root.sections";

export const getBuilderStaticProps = async (
  modelName: string,
  context: GetStaticPropsContext,
  path?: string,
  modles?: string[]
) => {
  const _path =
    path || `/${(context.params?.page as string[])?.join("/") || ""}`;
  // Don't target on url and device for better cache efficiency
  const targeting = { urlPath: "_", device: "_" } as any;

  const page = await builder
    .get(modelName, {
      userAttributes: { ...targeting, urlPath: _path },
    })
    .promise();

  const _sections = await getRootSections(modles || []);

  // If there is a Builder page for this URL, this will be an object, otherwise it'll be null
  return {
    props: {
      builderPage: page || null,
      sections: _sections,
    },
    revalidate: 5,
  };
};
