import { useState, useEffect } from "react";
import { tryPostApi } from "@lib/fetch-helpers";
import CartUpsellItem from "./cart-upsell-item.component";
import ProductProviderV2 from "@context/storefront.product.context";
import styles from "@styles/components/cart/cart-upsell.module.scss";

const base_path = process.env.NEXT_PUBLIC_BASE_PATH;

export default function CartUpsell() {
  const [collection, setCollection] = useState([]);

  const handleRequest = async (handle) => {
    await tryPostApi("/api/store/collectionV2", {
      options: { handle, to: 12 },
    }).then((resp) => {
      setCollection(resp.productList);
    });
  };

  useEffect(() => {
    if (collection.length === 0) {
      handleRequest("litter-bags");
    }
  }, []);

  return (
    <>
      <div className={styles.cartUpsellContainer}>
        <div className={styles.cartUpsellHeader}>
          Add a bag of litter to your order to qualify for free shipping
        </div>
        <div className={styles.cartUpsellItemsWrapper}>
          {collection &&
            collection.length > 0 &&
            collection.map((item, idx) => (
              <ProductProviderV2 product={item} key={idx}>
                <CartUpsellItem />
              </ProductProviderV2>
            ))}
        </div>
      </div>

      <a
        className={styles.freeShippingBannerLink}
        href={`https:${base_path}/collections/litter-bags`}
      >
        <span className={styles.freeShippingBanner}>
          <strong className={styles.upsellBold}>PSSSST—</strong>Add a bag of
          litter to qualify for FREE shipping!
        </span>
      </a>
    </>
  );
}
